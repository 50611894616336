<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getOrderByProduct"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <h2 id="sellOrderTitle" class="col-md-10 col-sm-9 col-8  font-weight-medium">
              {{ $t('report.txtSalesByOrder') }}
            </h2>
            <div class="col-md-2  col-sm-3 col-4 text-right text-success">
              <CButton
                block
                id="sellOrderExportButton"
                color="info"
                v-if="isExport"
                v-on:click="exportOrdrByProduct()"
              >
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12" >
              <show-date></show-date>
              <!-- <CRow class="form-group">
                  <CCol md="10" sm="9">
                    <select class="custom-select"  v-model="mailshopObjectId">
                      <option value="" selected>เลือก Email</option>
                      <option
                        v-for="mail in accessEmail"
                        :key="mail._id"
                        :value="mail._id" 
                      >
                        {{ mail.email }} 
                      </option> 
                    </select>
                  </CCol>
                  <CCol md="2" sm="3">
                    <CButton   v-on:click="getSellReport()" block color="success">
                      ตกลง
                    </CButton>
                  </CCol>
                </CRow> -->
              <hr />
              <div style="overflow-x: auto">
                <DataTable
                  id="sellOrderDataTable"
                  :items="getItems()"
                  :fields="fields"
                  :loading="loading"
                  hover
                  border
                  style="white-space: nowrap;"
                >
                </DataTable>
              </div>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getOrderByProduct">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
    DataTable,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      accessEmail: [],
      mailshopObjectId: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users', 'permissions']),
    isPermission() {
      return permis.findPermissionRead('orderDevice', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('orderDevice', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'date', label: this.$i18n.t('date') ,_classes: 'text-left font-weight-normal text-dark', },
        { key: 'orderNumber', label: this.$i18n.t('receiptNumber') ,_classes: 'text-left font-weight-normal text-dark', },
        { key: 'productPLU', label: this.$i18n.t('pluCode'),_classes: 'text-left font-weight-normal text-dark', },
        { key: 'name', label: this.$i18n.t('productName') ,_classes: 'text-left font-weight-normal text-dark', },
        {
          key: 'quantitySKU',
          label: this.$i18n.t('quantity'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'price',
          label: this.$i18n.t('priceUnit'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'netAmount',
          label: this.$i18n.t('netAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'discountAmount',
          label: this.$i18n.t('discount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'totalAmount',
          label: this.$i18n.t('totalAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        { key: 'user', label: 'Email',_classes: 'text-left font-weight-normal text-dark', },
        {
          key: 'status',
          label: this.$i18n.t('status'),
          _classes: 'text-left font-weight-normal text-dark',
        },
      ]
    },
  },
  created() {
    this.getOrderByProduct()
    //this.getAccessByshop()
  },
  methods: {
    ...util,
    getItems(){
      let data = this.data
      let detail = []
      let name = ''
      let PLUCode = ''
      let quantitySKU = ''
      let price = ''
      let netAmount = ''
      let discountAmount = ''
      let totalAmount = ''
      let classes = ''
      let user = ''
      let receiptNumber = ''
      // for (let i = 0; i < 5; i++) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].receiptItems.productPLU === undefined) {
          name = ''
          PLUCode = ''
          quantitySKU = ''
          price = ''
          netAmount = ''
          discountAmount = ''
          totalAmount = ''
        } else {
          name = data[i].receiptItems.productPLU.name
          PLUCode = data[i].receiptItems.productPLU.PLUCode
          quantitySKU = data[i].receiptItems.quantitySKU
          price = util.convertCurrency(data[i].receiptItems.price)
          netAmount = util.convertCurrency(data[i].receiptItems.netAmount)
          discountAmount = util.convertCurrency(
            data[i].receiptItems.discountAmount
          )
          totalAmount = util.convertCurrency(data[i].receiptItems.totalAmount)
        }

        if (data[i].User.email == undefined) {
          user = '-'
        } else {
          user = data[i].User.email
        }
        if (data[i].receiptStatus === 'V') {
          classes = 'text-danger'
        } else if (data[i].receiptStatus === 'A') {
          classes = 'text-black'
        } else if (data[i].receiptStatus === 'W') {
          classes = 'text-warning'
        }

        if (data[i].receiptNumber == undefined) {
          receiptNumber = '-'
        } else {
          receiptNumber = data[i].receiptNumber
        }

        detail.push({
          date: moment(String(data[i].created_at)).format('DD/MM/YYYY'),
          orderNumber: receiptNumber,
          productPLU: PLUCode,
          name: name,
          quantitySKU: quantitySKU,
          price: price,
          netAmount: netAmount,
          discountAmount: discountAmount,
          totalAmount: totalAmount,
          _classes: classes,
          user: user,
          status: data[i].order.status,
        })
      }
      return detail
    },
    getAccessByshop() {
      const uid = `${localStorage.getItem('shopsUid')}`
      axios
        .get('/shop/v1.0/' + uid + '/accessgranted/' + this.shopObjectId)
        .then((res) => {
          this.accessEmail = res.data.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getOrderByProduct(page = 1) {
      this.loadingButton = false
      this.loading = true

      const uid = `${localStorage.getItem('shopsUid')}`
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: this.shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      axios({
        url: '/api/v1.0/' + uid + '/order/getbyproduct/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportOrdrByProduct(page = 1) {
      const uid = `${localStorage.getItem('shopsUid')}`
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: this.shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page,
        limit: 50,
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      report({
        url: '/api/v1.0/' + uid + '/order/getbyproduct/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'รายงานสินค้าขายได้ตามเครื่องOrder.xlsx'
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
